import React from 'react';

const BusinessSpeedInfoCard = ({ speed }) => {
  const getSpeedDetails = (speedValue) => {
    if (speedValue <= 300) {
      return { label: 'Small Business' };
    } else if (speedValue <= 1000) {
      return { label: 'Medium Business' };
    } else {
      return { label: 'Large Business' };
    }
  };

  const { label } = getSpeedDetails(speed ?? 0);

  return (
    <div className="business-speed-info-card">
      <div className={`business-plans-label ${label?.toLowerCase()}`}>{label}</div>
    </div>
  )
}

export default BusinessSpeedInfoCard
