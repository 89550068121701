import React, { useState, useEffect, useCallback } from 'react';
import { useAccountCheck, useBusinessAddr, useCartData, useLoading, useMbox, useScheduleDate, useServiceablitityData, useSessionData, useStepsData, useVasData, useVendor } from '../../hooks/order';
import { clearSessionOrderId, getAggregatedAddress, getBaseUrl, getFixedCostWith$, getValid$Cost, getWirelessServRef, isUndefinedNullOrEmpty, replaceServiceName, getUpdatedServRef, getSessionOrderId, getProductPrice } from '../../common/common';
import { billingPeriod, cardErrorModalMessage, OrderStatus, routesConfig } from '../../util/util';
import { useDispatch } from 'react-redux';
import { saveFirstStep, saveWirelessPlanInfo, saveInternetPlan, resetState, updateErrorMsg, updateCardErrorModal, updateCustTC, submitOrder, updateOrder, setMBoxReqObj, saveVasPlanInfo, setSelectedBundle, saveScheduleDateTime, saveSpanishLanguagePreference, setRtsVisible, setActiveProductTab } from '../../store/action/OrderAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox, Switch } from 'antd';
import { getConfigs } from '../../config';
import { getOrderSubmissionModel } from '../../helpers/OrderHelperFunctions';
import InstalltionInfo from '../common/InstalltionInfo';
import CheckoutHeadText from '../Order/Checkout/CheckoutHeadText';
import MobileInfomration from '../common/MobileInfomration';
import moment from 'moment-timezone';
import { Loader } from './Loader';
import { EECproductRemoveFromCart, EECtransactionSuccess } from '../../common/GTMScripts';
import TermsInfo from './TermsInfo';
import { v4 as uuid } from 'uuid';
import useLeadsSDK from '../../hooks/useLeadsSDK';
import WhiModal from './WhiModal';
import RtsModal from './RtsModal';
import { cartLogo } from '../../images';
import PropTypes from 'prop-types';
import BroadbandInfo from './BroadbandInfo';

const config = getConfigs();

const CartInformation = (props) => {
  const leads = useLeadsSDK();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderInfo, sessionResponse } = useSessionData();
  const { loading, expressLoading, TCPADisclaimer } = useLoading();
  const { scheduleDateTime } = useScheduleDate();
  const { normalizedAddress, serviceabilityCheck, scheduleAppt } = useServiceablitityData();
  const { bundleData } = serviceabilityCheck;
  const { isBusinessAddress } = useBusinessAddr();
  const { mboxRequired = [] } = useMbox();
  const { paymentInfo, chgQuestionInfo } = useStepsData();
  const { checkAccount } = useAccountCheck();
  const { isElnkMailboxReq } = useMbox();
  let { servRef } = useCartData();
  const { isADSL, adslCost = 0, installationCost = 0, modemFee = 0, simCharge = 0, isInternetAdded, isMobileAdded, internetPlan, wirelessCartInfo, isProductsAdded, productCount, oneTimeCharge = 0, monthlyCharge = 0, setupCost, isWhiAdded, vasPlan, vasPlanInfo, errorMessage, isMobileContainer, total, serviceTerm, isBundleAdded, bundleDiscount, selectedBundleData, getOneTimeChargesHeadingValue, getOneTimeChargesLabel, getOneTimeChargesLabelValue, getMobilePriceLabel, mobileMrcCombinedVal, getMobilePriceValue, getMobilePrice, spanishLanguagePref, isMrcAndBundleDiscountApplied, selectedProduct } = useCartData();
  const { isVasOnlyFlow } = useVasData();
  const { password } = checkAccount;
  const { firstName, lastName, email, id, accountEmail, phoneNum, installPhone, fccLabel } = orderInfo;
  const billingAddress = isUndefinedNullOrEmpty(orderInfo.billingAddress) ? '' : orderInfo.billingAddress;
  const serviceAddress = getAggregatedAddress(normalizedAddress);
  const shippingAddress = isUndefinedNullOrEmpty(orderInfo.shippingAddress) ? '' : orderInfo.shippingAddress;
  const { wirelessPlanInfo = [] } = wirelessCartInfo;
  const { agentReadTC, encryptedCcn, encryptedCvc, integrityCheck, expirationDate } = paymentInfo;
  const { question, answer } = chgQuestionInfo;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [tcAgree, setTcAgree] = useState(false);
  const checkPage = window.location.href.includes('review-order');
  const prodPage = window.location.href.includes('product');
  const orderId = getSessionOrderId();
  const [showTerms, setShowTerms] = useState(true);
  const location = useLocation();
  const currentURL = location.pathname;
  const [showWhiAddedModal, setShowWhiAddedModal] = useState(false);
  const { vendor } = useVendor();
  const SHOW_TERMS_CONTRACT = config.SHOW_TERMS_CONTRACT === 'true';
  const [showBroadband, setShowBroadband] = useState(false);

  useEffect(() => {
    if (!orderId && sessionResponse.length !== 0 && !loading && !expressLoading) {
      window.location.href = getBaseUrl();
      dispatch(resetState);
    }
  }, [])

  // Delete Mobile Plan
  const handleRemoveWireless = (index, plan) => {
    servRef = getUpdatedServRef(plan, servRef);
    dispatch(updateOrder({ serviceReference: servRef }, id));
    if (wirelessPlanInfo.length && !isInternetAdded) {
      wirelessPlanInfo.splice(index, 1);
      dispatch(setActiveProductTab('mobile'))
      navigate(routesConfig.products)
      EECproductRemoveFromCart(plan)
    }
    wirelessPlanInfo.splice(index, 1)
    dispatch(saveWirelessPlanInfo({
      wirelessPlanInfo: wirelessPlanInfo,
      wirelesServeRef: getWirelessServRef(wirelessPlanInfo)
    }));
    EECproductRemoveFromCart(plan);
    if (isBundleAdded) {
      const { servLevel1 = '', servLevel2 = '', servLevel3 = '', servLevel4 = '' } = selectedBundleData || {};
      if ([servLevel1, servLevel2, servLevel3, servLevel4]?.includes(plan?.servLevel) || [servLevel1, servLevel2, servLevel3, servLevel4]?.includes('*')) {
        dispatch(setSelectedBundle(null));
      }
      dispatch(saveVasPlanInfo({
        vasPlan: [],
        vasProductCart: []
      }));
    }
  };

  const internetProductsAndWirelessEdit = (value) => {
    dispatch(setActiveProductTab(value))
    navigate(routesConfig.products)
  }
  const handleRemoveVas = (index, plan) => {
    if (checkPage || !window.location.href.includes('vasPage')) {
      servRef = getUpdatedServRef(plan, servRef);
      dispatch(updateOrder({ serviceReference: servRef }, id));
    }
    let mboxReq = mboxRequired;
    delete mboxReq[plan.servCode];
    dispatch(setMBoxReqObj(mboxReq));
    vasPlanInfo.vasPlan.splice(index, 1)
    vasPlanInfo?.vasProductCart?.splice(index, 1);
    dispatch(saveVasPlanInfo({
      vasPlan: vasPlanInfo.vasPlan,
      vasProductCart: vasPlanInfo.vasProductCart,
    }));
    EECproductRemoveFromCart(plan);

    if (isBundleAdded) {
      const { servLevel1 = '', servLevel2 = '', servLevel3 = '', servLevel4 = '' } = selectedBundleData || {};
      if ([servLevel1, servLevel2, servLevel3, servLevel4]?.includes(plan?.servLevel.toLowerCase())) {
        dispatch(setSelectedBundle(null));
      }
    }
  };
  const handleRemoveInternetProduct = () => {
    EECproductRemoveFromCart(internetPlan)
    dispatch(saveInternetPlan({}))
    if (isBundleAdded) {
      dispatch(setSelectedBundle(null));
      dispatch(saveVasPlanInfo({
        vasPlan: [],
        vasProductCart: []
      }));
      navigate(routesConfig.products);
    }
  }
  const getInternetProducts = (value) => {
    return (
      <li>
        <div className="item-plan d-flex j-between">
          <div>
            {replaceServiceName(internetPlan?.serviceName) + ' - First Month'}
          </div>
          <div className="price-text">{getValid$Cost(getProductPrice(internetPlan, serviceTerm))}{value ? billingPeriod.monthly : ''}
            <span style={{ padding: '0px 0px 0px 6px' }} className={`delete-product ${loading ? 'disable-pointer' : ''}`} onClick={handleRemoveInternetProduct} onKeyDown={()=>{}}>
              {!isProductsAdded && <i className="fa-solid fa-trash-can"></i>}
            </span>
            {isProductsAdded &&
              <span className={`delete-product ${loading ? 'disable-pointer' : ''}`} onClick={() => internetProductsAndWirelessEdit('internet')} onKeyDown={()=>{}}>
                <i className="fa fa-edit"></i>
              </span>
            }
          </div>
          <div className="dotted"></div>
        </div>
      </li>
    )
  };

  const getMobileProducts = (value) => {
    return (
      wirelessPlanInfo && wirelessPlanInfo.map((mobilePlan, i) => (
        <div className="item-plan d-flex j-between" key={i}>
          <div>
            ({mobilePlan.quantity}) Mobile {mobilePlan.serviceName.replace(' Plan', '')}
          </div>
          <div className="price-text">{getFixedCostWith$(mobilePlan.quantity * getMobilePrice(mobilePlan, 'cart'))}{value ? billingPeriod.monthly : ''}
            {isProductsAdded && <span className={`delete-product ${loading ? 'disable-pointer' : ''}`} onClick={() => internetProductsAndWirelessEdit('mobile')} onKeyDown={()=>{}}>
              <i className="fa fa-edit"></i>
            </span>
            }

            {!isProductsAdded && <span style={{ padding: '0px 0px 0px 6px' }} className={`delete-product ${loading ? 'disable-pointer' : ''}`} onClick={() => handleRemoveWireless(i, mobilePlan)} onKeyDown={()=>{}}>
              <i className="fa-solid fa-trash-can"></i>
            </span>
            }
          </div>
          <div className="dotted"></div>
        </div>
      ))
    )
  };
  const getVasProducts = (value) => {
    return (
      vasPlan && vasPlan.map((vasItem, i) => (
        <div className="item-plan d-flex j-between" key={i}>
          <div>
            <span className={`delete-product ${loading ? 'disable-pointer' : ''}`}
              onClick={() => handleRemoveVas(i, vasItem)} onKeyDown={()=>{}}>
            </span>
            {replaceServiceName(vasItem?.serviceName)}

          </div>
          <div className="price-text">{getValid$Cost(vasItem.price)}{value ? billingPeriod.monthly : ''}
            <span
              style={{ padding: '0 0 0 6px' }}
              className={`delete-product ${loading ? 'disable-pointer' : ''}`}
              onClick={() => {
                if (currentURL === routesConfig?.checkout || currentURL === routesConfig?.products) {
                  handleRemoveVas(i, vasItem)
                } else {
                  navigate(routesConfig.checkout)
                }
              }}
              onKeyUp={()=>{}}
            >
              {!isVasOnlyFlow && (
                currentURL === routesConfig?.checkout || currentURL === routesConfig?.products ?
                  <i className="fa-solid fa-trash-can"></i> :
                  <i className="fa-solid fa-edit"></i>
              )}
            </span>
          </div>
          <div className="dotted"></div>
        </div>
      ))
    )
  };

  // on Product page Checkout click
  const handleCheckout = () => {
    if (typeof props.handleContClick === 'function') {
      props.handleContClick();
    }
    dispatch(
      updateOrder(
        { orderStatus: OrderStatus.addedToCart, serviceReference: servRef, bbLabelServRef: servRef, promoCode: isBusinessAddress
          ? sessionResponse?.businessPromoCode
          : sessionResponse?.promoCode,
        partner: 'elnkwww', },
        id
      )
    );
    dispatch(saveFirstStep(!isProductsAdded));
  }
  const onCheckoutClick = () => {
    if (vendor?.toLowerCase() === 'verizon' && !scheduleAppt?.length) {
      dispatch(setRtsVisible(true));
    } else {
      handleCheckout();
      navigate(routesConfig.checkout);
    }
  }

  const toggleErrorModal = () => {
    setShowErrorModal(!showErrorModal);
  }

  const confirmOrder = () => {
    if (isWhiAdded && !isBusinessAddress) {
      setShowWhiAddedModal(true);
    } else {
      handleConfirmOrder();
    }
  }

  const handleConfirmOrder = () => {
    const bundleCode = isBundleAdded ? { ioPromoCode: bundleData?.find(el => el?.bundleCode === selectedBundleData?.bundleCode)?.bundleCode } : {};
    const promoCode = isBusinessAddress ? sessionResponse.businessPromoCode : sessionResponse.promoCode;
    const salesAgentId = sessionResponse.agentId;
    const customerAcceptedTC = tcAgree;
    const tcOrderAgreed = moment().format('lll');
    const affiliateId = sessionResponse.partnerId;
    const installWindow = config.SHOW_SCHEDULE_APPT === 'true' && !isUndefinedNullOrEmpty(scheduleDateTime) ? { installWindow: scheduleDateTime.replace(/.000/g, '') } : {};
    const PaswdCqas = config.SHOW_PSWD_CQAS === 'true' ? { answer, question, password } : {}
    const installPhoneValue = !isUndefinedNullOrEmpty(installPhone) ? { installPhone: installPhone } : {};

    if (!isUndefinedNullOrEmpty(id)) {
      const orderSubmissionModel = getOrderSubmissionModel({
        accountEmail,
        affiliateId,
        agentReadTC,
        billingAddress,
        creditCardCSV: encryptedCvc,
        creditCardExp: expirationDate,
        creditCardNum: (encryptedCcn + '||' + integrityCheck),
        customerAcceptedTC,
        email,
        firstName,
        lastName,
        orderId: id,
        phoneNum,
        promoCode,
        salesAgentId,
        serviceAddress,
        serviceReference: servRef,
        isExpressContainer: isVasOnlyFlow || isMobileContainer,
        ...installWindow,
        isElnkMailboxReq,
        ...PaswdCqas,
        ...installPhoneValue,
        TCPADisclaimer,
        bundleCode,
        spanishLanguagePref,
      });
      dispatch(updateCustTC(customerAcceptedTC));
      dispatch(submitOrder(orderSubmissionModel))
        .then((response) => {
          dispatch(updateOrder({
            confirmationNum: response.value.data.confirmationNo,
            accountNumber: response.value.data.customerNumber,
            installDate: response.value.data.reqInstallDate,
            tcOrderAgreed,
            orderStatus: OrderStatus.orderSubmit,
            xmlcErrorMsg: null
          }, id)).then(() => {
            const HLPlan = isInternetAdded ? [internetPlan] : []
            clearSessionOrderId();
            EECtransactionSuccess({
              id: response.value.data.confirmationNo,
              total,
              isADSL,
              products: [...HLPlan, ...wirelessPlanInfo, ...vasPlan],
              modemFee,
              isInternetAdded,
              isMobileAdded,
              installationCost,
              adslShippingCost: adslCost,
              simCharge
            })

            /**
             * For successful order, set confirmation number.
             */
            leads?.updateOrder?.('Update Order: Set Confirmation Number', {
              confirmationNumber: response?.value?.data?.confirmationNo,
            });
            navigate(routesConfig.thanks);
          }).catch((err) => {
            dispatch(updateErrorMsg(err));
            dispatch(resetState());
            dispatch(clearSessionOrderId());
          });
        })
        .catch((err) => {
          const errorPayload = isUndefinedNullOrEmpty(err.response) ? '' : isUndefinedNullOrEmpty(err.response.data) ? '' : err.response.data
          if (errorPayload?.msg || errorPayload?.type === 'actionDisplayAppError'
          ) {
            dispatch(updateOrder({
              xmlcErrorMsg: errorPayload.msg || errorPayload.error,
              orderStatus: OrderStatus.orderError
            }, id));
            let errMsg = isUndefinedNullOrEmpty(errorPayload.msg) ? isUndefinedNullOrEmpty(errorPayload.errorMessage) ?
              'There was an issue processing your order. Please call Affiliate Support.' : errorPayload.errorMessage : errorPayload.msg;
            dispatch(updateErrorMsg(errMsg));
          } else if (
            errorPayload.type &&
            errorPayload.type === 'actionUpdatePaymentInfo'
          ) {
            dispatch(updateOrder({
              xmlcErrorMsg: errorPayload.error,
              orderStatus: OrderStatus.orderError
            }, id)).then(() => {
            })
            dispatch(updateErrorMsg(errorPayload.type));
          } else if (
            errorPayload.type &&
            (errorPayload.type === 'actionDisplayErrorModal' ||
              errorPayload.type === 'actionDisplayPrepaidModal' ||
              errorPayload.type === 'actionDisplayFloorModal')
          ) {
            dispatch(updateCardErrorModal(cardErrorModalMessage[errorPayload.type]));
            dispatch(updateOrder({
              xmlcErrorMsg: errorPayload.error,
              orderStatus: OrderStatus.orderError
            }, id)).then(() => {
              toggleErrorModal();
            })
            dispatch(updateErrorMsg(errorPayload.type));
          } else {
            dispatch(updateErrorMsg('There was an issue processing your order. Please call Affiliate Support.'));
          }
        });
    }
  };

  const handleBackToHome = (action = 'err') => {
    const { orderStatus } = orderInfo;
    const orderStatusMsg = orderStatus === 'order-error/abandoned' ? 'CC-declined-abandoned' : OrderStatus.tcDeclined;
    dispatch(updateOrder({
      orderStatus: orderStatusMsg,
      tcOrderAgreed: orderStatusMsg,
    }, id)).then(() => {
      dispatch(resetState());
    });
    clearSessionOrderId();
    if (action === 'edit') {
      navigate(routesConfig.home);
    } else {
      window.location.href = sessionResponse?.homeUrl;
    }
  };

  const onChange = useCallback(() => {
    dispatch(saveSpanishLanguagePreference(!spanishLanguagePref));
  }, [spanishLanguagePref]);

  const getOneTimeChargeVal = useCallback(() => {
    if (isMrcAndBundleDiscountApplied) {
      return Number(Number(oneTimeCharge) - bundleDiscount)?.toFixed(2);
    }
    return Number(oneTimeCharge)?.toFixed(2);
  }, [isMrcAndBundleDiscountApplied, oneTimeCharge, bundleDiscount]);

  const handleResetAddress = () => {
    navigate(routesConfig.home)
    dispatch(saveScheduleDateTime(''));
    dispatch(saveInternetPlan({}))
    dispatch(setSelectedBundle(null));
    dispatch(saveWirelessPlanInfo({
      wirelessPlanInfo: [],
      wirelesServeRef: ''
    }));
    dispatch(setActiveProductTab(''))
  }

  return (
    <>
      <div className={`${checkPage ? 'elink-container cart-page p-top-5 height-big-screen' : 'fixed-on-scroll'} ${prodPage ? 'fixed-on-scroll-with-tab' : ''} ${isVasOnlyFlow ? 'vas-container' : ''}`}>
        <div className={`${checkPage && !showBroadband ? 'review-header' : ''}`}>
          <div className="d-none">
            <CheckoutHeadText />
          </div>
        </div>
        {showBroadband ?
          <BroadbandInfo back={() => setShowBroadband(false)} />
          :
          <div className={`responsive-none resutl-fix-scroll ${checkPage ? `main-review-part ${!isVasOnlyFlow ? 'd-flex j-between' : ''}` : 'resposive-space'}`}>
            <div className={`${checkPage ? 'review-left-side' : ''}`}>
              <div className="rightsidebar-cart" style={{marginTop: currentURL === routesConfig?.checkout && '0'}}>
                {checkPage ? (
                  <h4 className="checkout_review_heading">Review Order Details</h4>
                ) : (
                  <h3 className="on-result-remove">
                    <span style={{ marginRight: '8px' }}> <img src={cartLogo} /></span>
                    Cart {' '}
                    <span className="prdouct-count">({productCount})</span>{' '}
                  </h3>
                )}
                {productCount !== 0 ?
                  <>
                    {isProductsAdded &&
                      <div className="review_order_wrapp">
                        <div className="common-cart-section first-top">
                          <div className="service-cart">
                            <ul>
                              {firstName && <li>
                                <span className="cart_heading">Name</span>
                                <div className="item-plan">
                                  <div className="d-flex">{firstName} {lastName}</div>
                                </div>
                              </li>}
                              {(accountEmail || email) && <li>
                                <span className="cart_heading">Email Address</span>
                                <div className="item-plan">
                                  <div className="d-flex">{accountEmail ? accountEmail : email}</div>
                                </div>
                              </li>}
                              {!isVasOnlyFlow && !isMobileContainer &&
                                <li>
                                  <span className="cart_heading">{(shippingAddress?.length === 0 || shippingAddress === getAggregatedAddress(normalizedAddress)) ? 'Service / Shipping' : 'Service'}</span>
                                  <div className="item-plan">
                                    <div className="d-flex"><span className={`edit-icon ${loading ? 'disable-pointer' : ''}`}><i className="fa-solid fa-pen-to-square" onClick={() => {
                                      handleResetAddress()
                                    }} onKeyUp={() => { }}></i></span>{getAggregatedAddress(normalizedAddress)}</div>
                                  </div>
                                </li>
                              }
                              {shippingAddress?.length !== 0 && shippingAddress !== getAggregatedAddress(normalizedAddress) &&
                                <li>
                                  <span>Shipping Address</span>
                                  <div className="item-plan">
                                    <div className="d-flex">{shippingAddress}</div>
                                  </div>
                                </li>
                              }
                              {billingAddress?.length !== 0 && billingAddress !== getAggregatedAddress(normalizedAddress) &&
                                <li>
                                  <span>Billing Address</span>
                                  <div className="item-plan">
                                    <div className="d-flex">{billingAddress}</div>
                                  </div>
                                </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="review_order_wrapp p_review_order_wrapp">
                      {!isVasOnlyFlow &&
                        <div className="common-cart-section">
                          <div className="added-products one-time-change">
                            <div className="product-collect-heading d-flex j-between">
                              <p>One-time Charge(s)</p>
                              <p>{getOneTimeChargesHeadingValue()}</p>
                            </div>
                            <ul className="collected-product">
                              {isInternetAdded && <li>
                                <div className="item-plan d-flex j-between">
                                  <div>{getOneTimeChargesLabel()}</div>
                                  <div className="price-text"> {getOneTimeChargesLabelValue()}</div>
                                  <div className="dotted"></div>
                                </div>
                              </li>}
                              {isMobileAdded &&
                                <>
                                  {mobileMrcCombinedVal > 0 ? <>{wirelessPlanInfo?.map((mobilePlan) => (
                                    <li key={uuid()}>
                                      <div className="item-plan d-flex j-between">
                                        <div>{getMobilePriceLabel(mobilePlan)}</div>
                                        <div className="price-text">{getValid$Cost(getMobilePriceValue(mobilePlan)?.toFixed(2))}</div>
                                        <div className="dotted"></div>
                                      </div>
                                    </li>
                                  ))}
                                  <li>
                                    <div className="item-plan d-flex j-between">
                                      <div>SIM Card Fee</div>
                                      <div className="price-text">{getValid$Cost(simCharge?.toFixed(2))}</div>
                                      <div className="dotted"></div>
                                    </div>
                                  </li>
                                  </>
                                    : ''}
                                </>
                              }
                            </ul>
                          </div>
                        </div>
                      }
                      {!checkPage && <div className="b-top"></div>}
                      <div className="common-cart-section">
                        <div className="product-collect-heading d-flex j-between">
                          <p>Monthly Charges</p>
                          <p>{getValid$Cost(isBundleAdded ? (Number(monthlyCharge) - bundleDiscount).toFixed(2) : monthlyCharge)} / mo</p>
                        </div>
                        <div className="added-products">
                          <ul className="collected-product">
                            {isInternetAdded &&
                              <>
                                <li>
                                  <div className="item-plan d-flex j-between">
                                    <div>Modem Lease</div>
                                    <div className="price-text">
                                      {getValid$Cost(modemFee)}
                                    </div>
                                    <div className="dotted"></div>
                                  </div>
                                </li>
                                {getInternetProducts(false)}
                              </>
                            }
                            {isMobileAdded && getMobileProducts()}
                            {vasPlan && vasPlan.length > 0 && getVasProducts()}
                            {isBundleAdded && (
                              <div className="item-plan d-flex j-between">
                                <div style={{ flexDirection: 'column' }} >
                                  <div className="font-green">
                                    Bundle Discount Applied
                                  </div>
                                  <span>({selectedBundleData?.bundleName})</span>
                                </div>
                                <div className="price-text">
                                  ${bundleDiscount?.toFixed(2)}
                                </div>
                                <div className="dotted"></div>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div className="cart-terms">
                          <span className="term-title"
                            onClick={() => setShowTerms(!showTerms)}
                            onKeyUp={() => { }}
                          >Terms{/*
                        */}<span className="cart_terms_icon">
                              {showTerms ?
                                <i className="fa-solid fa-minus"></i> :
                                <i className="fa-solid fa-plus"></i>}
                            </span>
                            {' '}
                          </span>
                        </div>
                        {showTerms && (
                          <div className="terms-text"><p>{isMobileAdded && (mobileMrcCombinedVal > 0 ? ' ' : '*Mobile not charged until Activation')}</p>
                            <p>Plus Applicable Taxes and Fees</p>
                            <p>{internetPlan.contractLength && (`Internet ${SHOW_TERMS_CONTRACT ? internetPlan?.contractLength : '12'} Month Agreement`)}</p>
                            {isWhiAdded && <p>** Service automatically activates 10 days after Equipment delivery</p>}
                          </div>
                        )}
                      </div>
                      <div className="common-cart-section total-row ">
                        <div className={`${checkPage ? 'review-total-main' : ''}`}>
                          <div className="Total-with-checkout">
                            <div className="d-flex j-between">
                              <div className="text-left left-total">
                                <div>
                                  <b>Total</b>
                                </div>
                              </div>
                              <div className="d-flex j-end right-total">
                                {!isVasOnlyFlow && <div className="today-monthly-l">
                                  <div>
                                    <b>{isWhiAdded ? getValid$Cost(setupCost) : getValid$Cost(getOneTimeChargeVal())}</b>
                                  </div>
                                  <div>Today</div>
                                </div>}
                                <div className={`${!isVasOnlyFlow ? 'today-monthly-r' : ''}`}>
                                  <div>
                                    <b>{getValid$Cost(isBundleAdded ? (Number(monthlyCharge) - bundleDiscount).toFixed(2) : monthlyCharge)}</b>
                                  </div>
                                  <div>Monthly</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {checkPage && (isADSL || isInternetAdded || isWhiAdded)&&
                      <div className="common-cart-section total-row ">
                        <div className={`${checkPage ? 'review-total-main' : ''}`}>
                          <div className="Total-with-checkout">
                            <div className="d-flex j-between">
                              <div style={{cursor: !fccLabel?.serviceRef ? 'not-allowed' : 'pointer', opacity:!fccLabel?.serviceRef && '0.5'}}>
                                <span className="cart_terms_icon" onClick={() => !fccLabel?.serviceRef ? undefined :setShowBroadband(true)}>
                                  <b>Broadband Facts</b> &nbsp;
                                  <i className="fas fa-external-link-alt" style={{ color: '#007cba', cursor: !fccLabel?.serviceRef ? 'not-allowed' : 'pointer' }}></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>}
                    </div>
                    {checkPage && (
                      <div className="review-terms-condition">
                        <div className="btn-with-toogle">
                          <h5 className="term_heading">EarthLink Terms and Conditions</h5>
                          {currentURL === routesConfig?.reviewOrder && (
                            <Switch
                              onChange={onChange}
                              checkedChildren="Spanish"
                              unCheckedChildren="English"
                              checked={spanishLanguagePref}
                            />
                          )}
                        </div>
                        <div className="terms-description-upper">
                          <div className="terms-description">
                            <TermsInfo />
                          </div>
                        </div>

                        {!spanishLanguagePref && <Checkbox onClick={() => setTcAgree(!tcAgree)} disabled={loading}>
                          I am at least 18 years old and agree to EarthLink&apos;s <a href={`${getBaseUrl()}tcs`} target="_blank" rel="noreferrer">Terms</a>, including the Service Agreement, <a href={`${getBaseUrl()}tcs/acceptable-use-policy/`} target="_blank" rel="noreferrer">Acceptable Use Policy</a>, and <a href={`${getBaseUrl()}tcs/privacy-policy/`} target="_blank" rel="noreferrer">Privacy Policy</a>, and to the terms in this purchase order.
                        </Checkbox>}

                        {spanishLanguagePref && (
                          <Checkbox
                            onClick={() => setTcAgree(!tcAgree)}
                            disabled={loading}
                          >
                            Tengo al menos 18 años y estoy de acuerdo con
                            EarthLink&apos;s{' '}
                            <a
                              href={`${getBaseUrl()}tcs`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Términos
                            </a>
                            , incluyendo el Acuerdo de Servicio,{' '}
                            <a
                              href={`${getBaseUrl()}tcs/acceptable-use-policy-es/`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Política de uso aceptable
                            </a>
                            , y{' '}
                            <a
                              href={`${getBaseUrl()}tcs/privacy-policy-spanish/`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              política de privacidad
                            </a>{/*
                      */}, y a los términos de esta orden de compra.
                          </Checkbox>
                        )}
                      </div>
                    )}
                    <div className="review-button">
                      {checkPage ? (
                        <>
                          {errorMessage &&
                            <div className="red-color p-left-right-25">
                              Looks like there was an error processing your order.  Please contact our Sales center for assistance. <br /> Call <a style={{ color: 'red' }} href={`tel:${sessionResponse.abaTfn}`}>{sessionResponse.abaTfn.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}</a>
                            </div>
                          }
                          {
                            !loading && !errorMessage ?
                              <button
                                className="blue-button"
                                disabled={!tcAgree}
                                onClick={() => confirmOrder()}>
                                Submit Order
                              </button>
                              :
                              !isUndefinedNullOrEmpty(errorMessage) ?
                                <button
                                  className="blue-button"
                                  onClick={() => handleBackToHome()}
                                > BACK TO HOME </button>
                                :
                                <div>
                                  <Loader />
                                </div>
                          }
                        </>
                      ) : !isProductsAdded && (
                        <button className="blue-button" disabled={!isMobileAdded && !isInternetAdded} onClick={() => {
                          onCheckoutClick()
                        }}>Checkout</button>
                      )}
                    </div>
                  </> :
                  <>
                    <div className="b-top result-none"></div>
                    <div className="cart-empty">
                      Cart is empty
                    </div>
                  </>
                }
              </div>
            </div>
            {!isVasOnlyFlow && <div className={`${checkPage ? 'review-right-side' : ''}`}>
              <div className={`${checkPage ? 'fixed-on-scroll' : ''}`}>
                {isInternetAdded && !checkPage && <div className="m-top-2"></div>}
                {isInternetAdded && <InstalltionInfo oneTimeCharge={isWhiAdded ? getValid$Cost(setupCost) : getValid$Cost(installationCost)} internetPlan={internetPlan} />}
                {<div className={`${isMobileAdded && !isInternetAdded && checkPage ? '' : 'm-top-2'}`}></div>}
                {isMobileAdded && <MobileInfomration />}
              </div>
            </div>}
          </div>
        }
      </div>
      <WhiModal showWhiAddedModal={showWhiAddedModal} setShowWhiAddedModal={setShowWhiAddedModal} handleConfirmOrder={handleConfirmOrder} selectedProduct={selectedProduct} />
      <RtsModal handleCheckout={handleCheckout} />
    </>
  )
}


CartInformation.propTypes = {
  handleContClick: PropTypes.func.isRequired
};

export default CartInformation;
